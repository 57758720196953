<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <!-- <button
                  type="button"
                  class="btn btn-success float-right"
                  data-toggle="modal"
                  data-target="#modal-form-postulacion-export"
                  @click="$refs.PostulacionExport.resetFilter()"
                >
                  <i class="far fa-file-excel"></i>
                </button> -->
              </div>

              <div class="card-body table-responsive p-0">
                <table class="table table-bordered table-striped table-hover table-sm text-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 6em;">
                        #
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.id"
                          @input="init()"
                        />
                      </th>
                      <th>
                        Tipo Solicitud
                        <v-select
                          v-model="filtros.tipos_solicitudes"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                          :options="listasForms.tipoSolicitudes"
                          :filterable="false"
                          @input="init()"
                        ></v-select>
                      </th>
                      <th>
                        Solicitante
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="init()"
                        />
                      </th>
                      <th>
                        Fecha
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha"
                          @input="init()"
                        />
                      </th>
                      <th>Justificación</th>
                      <th>Observación</th>
                      <th>Material</th>
                      <th
                        style="width: 7em;"
                        class="text-wrap"
                      >Presupuesto Verificado</th>
                      <th>
                        Estado
                        <v-select
                          v-model="filtros.estados"
                          class="form-control form-control-sm p-0"
                          :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                          label="descripcion"
                          :options="listasForms.estados"
                          :filterable="false"
                          @input="init()"
                        ></v-select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="soli in solicitudes.data" :key="soli.id">
                      <td class="text-center">{{ soli.id }}</td>
                      <td class="text-center">
                            <span class="badge"
                              :class="soli.tipo_solicitud == 1 ? 'bg-navy' : 'bg-danger'"
                            >{{ soli.nTipoSolicitud }}</span>
                            <div class="row justify-content-center"
                              v-if="soli.tipo_emergencia == 2"
                            >
                              <span class="badge bg-pink">{{soli.nTipoEmergencia}}</span>
                            </div>
                          </td>
                      <td>{{ soli.user.name }}</td>
                      <td class="text-center">{{soli.fecha}}</td>
                      <td class="text-wrap"><small>{{ soli.justificacion }}</small></td>
                      <td class="text-wrap"><small>{{ soli.observaciones }}</small></td>
                      <td class="text-center">
                        <i 
                          class="fa"
                          :class="soli.material_inventarios ? 'fa-check text-success' : 'fa-times-circle text-danger'"
                        ></i>
                      </td>
                      <td class="text-center">
                        <i 
                          class="fa"
                          :class="soli.presupuesto_verificado ? 'fa-check text-success' : 'fa-times-circle text-danger'"
                        ></i>
                      </td>
                      <td class="text-center">
                        <span class="badge"
                          :class="
                            soli.estado == 1 ? 'bg-warning' :
                            soli.estado == 2 ? 'bg-success' : 
                            soli.estado == 4 ? 'bg-lime' : 
                            'bg-secondary'
                          " 
                        >
                          {{ soli.nEstado }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            v-if="$store.getters.can('cs.nacionalesPostulaciones.edit')"
                            @click="asignarPostulaciones(soli, 1)"
                            class="btn btn-sm bg-navy"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            v-if="$store.getters.can('cs.nacionalesPostulaciones.show')"
                            @click="asignarPostulaciones(soli, 2)"
                            class="btn btn-sm bg-primary"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  <p>
                    Mostrando del <b>{{ solicitudes.from }}</b> al
                    <b>{{ solicitudes.to }}</b> de un total:
                    <b>{{ solicitudes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="init"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <PostulacionExport ref="PostulacionExport" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import PostulacionExport from "./PostulacionExport";
export default {
  name: "PostulacionIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    PostulacionExport,
  },
  data() {
    return {
      cargando: true,
      distancia: null,
      solicitudes: {},
      filtros: {},
      listasForms: {
        tipoSolicitudes: [],
        estados: [],
      },
      empresas: [],
    };
  },

  methods: {
    init(page = 1) {
      this.cargando = true;
      let me = this;

      // Se ajustan los filtros
      this.filtros.tipo_solicitud = this.filtros.tipos_solicitudes ? this.filtros.tipos_solicitudes.numeracion : null;
      this.filtros.estado = this.filtros.estados ? this.filtros.estados.numeracion : null;

      axios
        .get("/api/cs/postulacionesNacionales?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.solicitudes = response.data;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    // Métodos para llenado de filtros 
    getTipoSolicitudes() {
      axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipoSolicitudes = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/142").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    // Métodos de acciónes del módulo
    asignarPostulaciones(data, accion){
      return this.$router.push({
        name: "/Cs/PostulacionesNacionalesForm",
        params: {
          solicitud: data,
          accion: accion,
        },
      });
    },
  },
  mounted() {
    this.init();
    this.getEstados();
    this.getTipoSolicitudes();
  },
};
</script>
